import { type ServiceMethod } from '@/application/interfaces/service';
import { type ItemId } from '@/infra/services/catalog/model';
import { type Seller } from '@/infra/services/catalog/product/get-sellers/model';

import { getProductInfoFixture } from './fixture';
import { type Product } from './model';

export type Params = {
  itemId: ItemId;
};

export type Response = {
  data: {
    product: Product;
    currentSeller: Seller;
  };
};

const getProductInfo: ServiceMethod<Response, Params> =
  (httpClient) => async (data, options) => {
    const { itemId } = data;

    const { body: response } = await httpClient.get<Response, Params>(
      '/api/v3/products/{itemId}',
      {
        ...options,
        params: {
          itemId,
        },
      }
    );

    // Mockado até os backs ajustarem o preço para float e adicionarem
    // os stamps e medias
    return {
      ...response,
      data: {
        ...response.data,
        product: {
          ...response.data.product,
          price: getProductInfoFixture.data.product.price,
          stamps: getProductInfoFixture.data.product.stamps,
          medias: getProductInfoFixture.data.product.medias,
        },
      },
    };
  };

export default getProductInfo;
