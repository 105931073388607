'use client';

import {
  Button,
  Container,
  Heading,
  Flex,
  Text,
  Spacer,
} from '@backyard-ui/core';

import { Image } from '../Image';

interface ErrorProps {
  reset: () => void;
}

const ErrorComponent = (props: ErrorProps) => {
  return (
    <section className="py-20">
      <Container size="6xl">
        <Flex
          direction={{
            initial: 'column',
            lg: 'row-reverse',
          }}
          justify="space-between"
          align="center"
        >
          <Flex basis="40%">
            <Image
              className="w-full object-contain"
              src="/images/500-error.svg"
              alt="Imagem ilustrativa indicando um erro no servidor"
              width={487}
              height={426}
            />
          </Flex>

          <Flex
            direction="column"
            align={{
              initial: 'stretch',
              lg: 'start',
            }}
            basis="40%"
          >
            <Heading size="3xl">Imprevistos acontecem...</Heading>
            <Spacer size={6} axis="vertical" />
            <Text size="lg">
              Até as melhores casas precisam de manutenção às vezes. Estamos
              trabalhando para consertar isso o mais rápido possível.
              Provavelmente em alguns minutos já estaremos funcionando
              normalmente.
            </Text>
            <Spacer size={14} axis="vertical" />
            <Button size="md" onClick={props.reset}>
              Tentar novamente
            </Button>
          </Flex>
        </Flex>
      </Container>
    </section>
  );
};

export default ErrorComponent;
