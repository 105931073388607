import { Heading, SimpleGrid, Stack } from '@backyard-ui/core';

import { Image } from '@/presenters/components/common/Image';

import { styles } from './Social.styles';

import { SocialIcon } from '.';

function Social() {
  const classNames = styles();

  return (
    <div className={classNames.base()} data-testid="footer-social">
      <SimpleGrid
        columns={{
          initial: 1,
          sm: 2,
          md: 2,
          lg: 3,
        }}
        gapX="6"
      >
        <Stack direction="column" spacing={4} className={classNames.card()}>
          <Heading size="md" asChild>
            <h3>Baixe nosso aplicativo</h3>
          </Heading>
          <Stack>
            <a
              title="Baixar na App Store"
              rel="noopener noreferrer"
              href="https://apps.apple.com/br/app/leroy-merlin-brasil/id1470425305"
              target="_blank"
            >
              <SocialIcon name="appStore" />
            </a>
            <a
              title="Disponível no Google Play"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=br.com.leroymerlin"
              target="_blank"
            >
              <SocialIcon name="playStore" />
            </a>
          </Stack>
        </Stack>

        <Stack direction="column" spacing={4} className={classNames.card()}>
          <Heading size="md" asChild>
            <h3>Siga nossos perfis</h3>
          </Heading>

          <Stack spacing={6}>
            {[
              {
                slug: 'tiktok',
                title: 'TikTok - Leroy Merlin',
                href: 'https://www.tiktok.com/@leroymerlinbrasil',
              },
              {
                slug: 'instagram',
                title: 'Instagram - Leroy Merlin',
                href: 'https://www.instagram.com/leroymerlinbrasil/',
              },
              {
                slug: 'pinterest',
                title: 'Pinterest - Leroy Merlin',
                href: 'https://www.pinterest.com/leroymerlinbr',
              },
              {
                slug: 'youTube',
                title: 'YouTube - Leroy Merlin',
                href: 'https://www.youtube.com/user/LeroyMerlinBRA',
              },
            ].map((item) => (
              <a
                key={item.slug}
                title={item.title}
                href={item.href}
                rel="noopener noreferrer"
                target="_blank"
              >
                <SocialIcon name={item.slug} />
              </a>
            ))}
          </Stack>
        </Stack>

        <Stack direction="column" spacing={4} className={classNames.card()}>
          <Heading size="md" asChild>
            <h3>Certificados</h3>
          </Heading>
          <a
            title="Selo Site Seguro- CertiSign"
            href="https://www.certisign.com.br/seal/splashcerti.htm"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Image
              src="/images/footer/footer-certisign.png"
              alt="Um site validado pela Certisign indica que nossa empresa concluiu satisfatoriamente todos os procedimentos para determinar que o domínio validado é de propriedade ou se encontra registrado por uma empresa ou organização autorizada a negociar por ela ou exercer qualquer atividade lícita em seu nome."
              width={133}
              height={52}
            />
          </a>
        </Stack>
      </SimpleGrid>
    </div>
  );
}

export default Social;
