import { Skeleton as BackyardSkeleton, Stack } from '@backyard-ui/core';

import { styles as productThumbStyles } from '@/presenters/components/common/ProductThumb/ProductThumb.styles';

interface SkeletonProps {
  testId?: string;
}

const Skeleton = ({ testId = 'skeleton' }: SkeletonProps) => (
  <Stack direction="column" spacing={4} data-testid={testId}>
    <BackyardSkeleton width="250px" height="34px" />
    <Stack spacing={2} className="overflow-hidden">
      {Array.from({ length: 6 })
        .fill('')
        .map((_, index) => (
          <div
            key={index}
            className={productThumbStyles().base({ className: 'shrink-0' })}
          >
            <BackyardSkeleton
              width="100%"
              height="100%"
              UNSAFE_className="absolute inset-0"
            />
          </div>
        ))}
    </Stack>
  </Stack>
);

export default Skeleton;
