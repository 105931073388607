import { type Response } from './get-product-info';

export const getProductInfoFixture: Response = {
  data: {
    product: {
      _id: 90579741,
      brand: 'Einhell',
      characteristics: [{ name: 'characteristics 1', value: '1' }],
      description: 'Instalação de AR',
      disclaimer: 'Preparação de toda infra e instalação',
      discount: {
        discountPercentage: 1,
        discountType: '',
        paymentMethodsDiscount: '1',
      },
      fullDescription: 'Preparação de toda infra e instalação',
      name: 'Instalação',
      short_name: 'Instalação',
      price: {
        from: 10,
        to: 9,
      },
      visualMedias: {
        medias: [
          {
            main: 'https://cdn.leroymerlin.com.br/products/piso_laminado_click_elmo_ravena_136x21,7cm_m2_artens_89238114_e079_300x300.jpg',
            alt: '',
          },
        ],
      },
      stamps: [],
      medias: [
        {
          type: 'army-certificate',
          url: 'https://cdn-homolog-us.leroymerlin.com.br/medias/document-89395180-army-certificate-furadeira-de-impacto-makita-com-maleta-e-acessorios-760w-1-2-hp1640-l-127v.pdf?_gl=1*1wjwbqz*_ga*NjYwMTc2OTE2LjE3MjkwMjA4ODY.*_ga_R7WD44QNMH*MTczNDQ0MTc5Mi4zNy4xLjE3MzQ0NDU5ODkuMC4wLjA.',
          title: 'Certificado de Registro do Exército',
        },
        {
          type: 'fsc',
          url: '',
          title: 'FSC®',
        },
      ],
      ean: '4006825618709',
      isAvailableOnEcommerce: false,
      isExclusiveMarketPlace: true,
      isExclusiveOnEcommerce: false,
      url: '',
      unit: '',
    },
    currentSeller: {
      id: '',
      selected: true,
      isFreeShipping: true,
      isOutlet: false,
      pricing: {
        discount: {
          paymentMethods: 'à vista',
          type: 'payment-method',
          percentage: 6,
        },
        price: {
          to: 339,
          from: 360.64,
        },
        installment: {
          value: 120.21,
          quantity: 3,
          totalValue: 360.64,
          brandedValue: 36.06,
          brandedQuantity: 10,
          brandedTotalValue: 360.64,
        },
      },
      ecommerceScopes: {
        canPurchase: true,
        canPickupInStore: true,
        canConsultEcommerceStock: true,
      },
      assistedSalesScopes: {
        canPurchase: false,
        canConsultStock: false,
        canConsultBatchSearch: false,
      },
      isPrioritizedInTheBuyBox: null,
      quantityLimitMessage: null,
      skuInstance: null,
      shop: {
        id: 'leroy',
        name: 'Leroy Merlin',
        logo: 'https://example.com.br/assets/images/logo-leroy-merlin.svg',
      },
    },
  },
};
