// eslint-disable-next-line no-restricted-imports
import NextImage, { type ImageProps } from 'next/image';

const isDevelopment = process.env.NODE_ENV === 'development';
const cdnUrl = process.env.NEXT_PUBLIC_CDN_URL;
const appVersion = process.env.NEXT_PUBLIC_APP_VERSION;

const getImageUrl = (src: string) => {
  if (isDevelopment || !cdnUrl || !appVersion || !src.startsWith('/')) {
    return src;
  }

  return `${cdnUrl}/ssr/${appVersion}/_next${src}`;
};

function Image(props: ImageProps & { src: string }) {
  const normalizedSrc = getImageUrl(props.src);

  return <NextImage {...props} src={normalizedSrc} />;
}

export default Image;
