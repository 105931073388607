'use client';

import { useId } from 'react';

import { useInView } from 'react-intersection-observer';

import { type ProductContext } from '@/infra/services/new-tail/model';
import {
  ProductsCarousel,
  ProductsCarouselSkeleton,
} from '@/presenters/components/common/ProductsCarousel';
import { useRecommendedProducts } from '@/presenters/hooks/use-recommended-products';
import { useRunOnce } from '@/presenters/hooks/use-run-once';

import useProductsAds from './useProductsAds';
import { filterProductsAdsByProducts } from './utils';

interface RetailMediaProductsCarouselProps {
  context: ProductContext;
  title?: string;
}

function RetailMediaProductsCarousel(
  props: Readonly<RetailMediaProductsCarouselProps>
) {
  const { context, title = 'Produtos Patrocinados' } = props;

  const id = useId();
  const { ref, inView } = useInView();

  const {
    data: productsAdsData,
    isError: isProductsAdsError,
    isFetching: isFetchingProductsAds,
    isFetched: isFetchedProductsAds,
  } = useProductsAds(context, id);

  const {
    data: productsData,
    isError: isProductsError,
    isFetching: isFetchingProducts,
    isFetched: isFetchedProducts,
  } = useRecommendedProducts({
    products:
      productsAdsData?.productsAds.map(({ product_sku }) => ({
        id: product_sku,
        clickTrackingURL: '',
      })) || [],
    title: '',
  });

  const filteredProductsAds = filterProductsAdsByProducts({
    productsAds: productsAdsData?.productsAds || [],
    products: productsData?.products || [],
  });

  useRunOnce(() => {
    filteredProductsAds.forEach(({ impression_url }) => {
      navigator.sendBeacon(impression_url);
    });
  }, Boolean(filteredProductsAds.length));

  useRunOnce(() => {
    filteredProductsAds.forEach(({ view_url }) => {
      navigator.sendBeacon(view_url);
    });
  }, inView && Boolean(filteredProductsAds.length));

  const hasError = isProductsAdsError || isProductsError;
  const isFetched = isFetchedProductsAds && isFetchedProducts;

  const isFetching =
    isFetchingProductsAds ||
    (isFetchingProducts && Boolean(productsAdsData?.productsAds.length));

  const hasNoProducts = !isFetching && !productsData?.products.length;

  if (isFetching || !isFetched) {
    return <ProductsCarouselSkeleton testId="retail-media-skeleton" />;
  }

  if (hasError || hasNoProducts) {
    return null;
  }

  return (
    <div ref={ref}>
      <ProductsCarousel
        partnerName="retail media"
        type="products"
        title={title}
        items={productsData?.products || []}
        onProductClick={(_, { id: productId }) => {
          const productAds = filteredProductsAds.find(
            ({ product_sku }) => Number(product_sku) === productId
          );

          if (productAds) {
            navigator.sendBeacon(productAds.click_url);
          }
        }}
      />
    </div>
  );
}

export default RetailMediaProductsCarousel;
