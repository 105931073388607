'use client';

import type { PropsWithChildren } from 'react';

import { useSearchParams } from 'next/navigation';

import { Configure } from 'react-instantsearch';
import { InstantSearchNext } from 'react-instantsearch-nextjs';

import { useAnalyticsTags } from '@/presenters/hooks/algolia/useAnalyticsTags';
import { useGlobalStore } from '@/presenters/store/global';
import { indexName, customSearchClient, insightsConfig } from '@/utils/algolia';

const HITS_PER_PAGE = 36;
const MAX_VALUES_PER_FACET = 1000;
const STALLED_SEARCH_DELAY = 2000;

interface AlgoliaSearchProviderProps extends PropsWithChildren {}

function AlgoliaSearchProvider(props: Readonly<AlgoliaSearchProviderProps>) {
  const { children } = props;

  const { region } = useGlobalStore((store) => ({
    region: store.user.session.region,
  }));

  const defaultFilter = `regionalAttributes.${region}.promotionalPrice > 0 AND regionalAttributes.${region}.available=1`;

  const searchParams = useSearchParams();

  const analyticsTags = useAnalyticsTags();

  const query = searchParams.get('term') || '';
  const page = searchParams.get('page');

  return (
    <InstantSearchNext
      indexName={indexName}
      insights={insightsConfig}
      initialUiState={{
        [indexName]: {
          query,
        },
      }}
      searchClient={customSearchClient}
      future={{ preserveSharedStateOnUnmount: true }}
      stalledSearchDelay={STALLED_SEARCH_DELAY}
    >
      {region && (
        <Configure
          facets={['*']}
          filters={defaultFilter}
          hitsPerPage={HITS_PER_PAGE}
          analyticsTags={analyticsTags}
          maxValuesPerFacet={MAX_VALUES_PER_FACET}
          page={Math.max(Number(page) - 1, 0) || 0}
          analytics
          clickAnalytics
          facetingAfterDistinct
        />
      )}

      {children}
    </InstantSearchNext>
  );
}

export default AlgoliaSearchProvider;
